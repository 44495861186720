@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");

:root {
    overflow-x: hidden;
}

body {
    scroll-behavior: smooth;
    font-family: 'Montserrat', sans-serif;
}

.page {
    padding-top: 85px;
}

.banner-img {
    width: 100%;
    height: auto;
}

.dropdown:hover>.dropdown-menu {
    display: block;
}

.dropdown>.dropdown-hover:active {
    pointer-events: none;
}

.dropdown-item {
    cursor: pointer;
}

.dropdown-menu {
    transform: translateX(-50%);
}

.title {
    font-size: 50px;
    padding: 88px 0;
}

.text-title-details {
    font-weight: 700;
    font-size: 50px;
    line-height: 1;
}

.text-details {
    font-weight: 500;
    font-size: 20px;
}

/* .row-item {
    padding-bottom: 50px;
} */

.col-item {
    padding-bottom: 128px;
}

/* .footer {
    padding-left: 40px;
} */

.text-footer {
    cursor: pointer;
    color: white;
    font-weight: 400;
    font-size: 13px;
    margin: 0 50px;
}

.content {
    margin: 0 128px;
}

.term-title {
    font-size: 24px;
    line-height: 32px;
    padding: 12px;
}

.term-text {
    line-height: 28px;
    padding: 4px;
    font-weight: 400;
    margin: 0;
}

.term-content {
    padding: 0 112px 64px;
}

.text-lg {
    font-size: 18px;
    line-height: 28px;
}

@media (max-width: 540px) {
    .title {
        font-size: 30px;
        padding: 88px 0;
    }

    .text-title-details {
        font-weight: 700;
        font-size: 30px;
        line-height: 1;
    }

    .text-details {
        font-weight: 500;
        font-size: 15px;
    }

    .term-content {
        padding: 0 20px 64px;
    }

    .col-item {
        padding-bottom: 64px;
    }
}

@media (max-width: 960px) {
    
}

@media (max-width: 1280px) {
    .content {
        margin: 0 64px;
    }
}